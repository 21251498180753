import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ElementRef,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';
import { GraphQLService } from '../../services/graph-ql.service';
import { Subscription } from 'rxjs';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { Menu } from '../../models/menu.model';
import { LowerCasePipe, NgFor, NgIf, CommonModule } from '@angular/common';
import { MenubarModule } from 'primeng/menubar';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    NgIf,
    NgFor,
    RouterLinkActive,
    MenubarModule,
    LowerCasePipe,
  ],
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  @Input() display = 'inline';
  @Input() menuName = 'main';
  @ViewChild('menuBar') menuBar: any;

  public menu: Menu | null = null;
  public processedMenu: MenuItem[] = [];
  public menuOpen = false;

  private subscription = new Subscription();
  private clickListener: (() => void) | null = null;

  constructor(
    private graphQLService: GraphQLService,
    private renderer: Renderer2,
    private el: ElementRef,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const sub = this.graphQLService.getMenu(this.menuName).subscribe((menu) => {
      if (menu.data.menu) {
        this.menu = menu.data.menu;
        this.processItems();
        if (this.menuName === 'MAIN') {
          this.processedMenu.push({
            label: 'Zoeken',
            url: '/zoeken',
            class: 'md:hidden mb-zoek',
          });
        }
      }
    });
    this.subscription.add(sub);

    this.clickListener = this.renderer.listen('document', 'click', (event: MouseEvent) => {
      this.handleDocumentClick(event);
    });
  }

  getMenuItemClasses(menuItem: MenuItem): string {
    let classes = '';

    if (menuItem.items && menuItem.items.length > 0) {
      classes += 'menu-item-with-children ';
    }

    if (menuItem.class === 'hidden') {
      classes += 'hidden ';
    } else if (menuItem.class) {
      classes += menuItem.class + ' ';
    }

    return classes.trim();
  }

  processItems(): void {
    if (this.menu?.items) {
      this.menu.items.forEach((item) => {
        const children = item.children?.map((child) => ({
          label: child.title,
          routerLink: child.internal ? child.url : undefined,
          url: !child.internal ? child.url : undefined,
          class: child.title === 'Ontoegankelijk' ? 'hidden' : '',
        }));

        this.processedMenu.push({
          label: item.title,
          routerLink: item.internal ? item.url : undefined,
          url: !item.internal ? item.url : undefined,
          items: children,
          class: item.title === 'Ontoegankelijk' ? 'hidden' : '',
        });
      });
    }
  }

  toggleMenu(): void {
    this.menuOpen = !this.menuOpen;
    const menuElement = this.el.nativeElement.querySelector('.p-menubar');

    if (this.menuOpen) {
      this.renderer.addClass(menuElement, 'p-menubar-mobile-active');
    } else {
      this.renderer.removeClass(menuElement, 'p-menubar-mobile-active');
    }

    this.cdr.detectChanges();
  }

  handleDocumentClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    const isToggleClick = !!target.closest('.menu-toggle-txt');
    const isLogoClick = !!target.closest('.logo-region');

    if (isLogoClick) {
      this.menuOpen = false;

      const menuElement = this.el.nativeElement.querySelector('.p-menubar');
      if (menuElement) {
        this.renderer.removeClass(menuElement, 'p-menubar-mobile-active');
      }

      this.cdr.detectChanges();
    }

    if (isToggleClick) {
      this.toggleMenu();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    if (this.clickListener) {
      this.clickListener();
    }
  }
}
