<div class="menu w-full menu--{{menuName|lowercase}}" *ngIf="menu">
  <ng-container *ngIf="menuName == 'MAIN' else footerMenu">
    <p-menubar
      #menuBar
      [ngClass]="{ 'mobile-active': menuOpen }"
      [model]="processedMenu"
      autoDisplay="true">
      <div class="flex items-right sm:block md:hidden ipad:block tablet:block menu-toggle right-0">
        <div class="menu-toggle-txt text-white text-base font-normal leading-6"
        (click)="toggleMenu()">Menu</div>
        <ng-template pTemplate="menuicon">
          <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M33.25 9.5L33.25 12.6667L12.6667 12.6667L12.6667 9.5L33.25 9.5ZM6.96667 11.0833L14.8833 19L6.96667 26.9167L4.75 24.7L10.45 19L4.75 13.3L6.96667 11.0833ZM33.25 17.4167L33.25 20.5833L17.4167 20.5833L17.4167 17.4167L33.25 17.4167ZM33.25 25.3333L33.25 28.5L12.6667 28.5L12.6667 25.3333L33.25 25.3333Z" fill="#F8D100"></path></svg>
        </ng-template>
      </div>
      <ng-template pTemplate="item" let-menuItem let-i="index">
        <a
          [ngClass]="getMenuItemClasses(menuItem)"
          class="p-ripple p-element p-menuitem-link"
          [href]="menuItem.url"
          routerLinkActive="p-menuitem-link-active"
          tabindex="-1"
          data-pc-section="action"
          *ngIf="menuItem.url"
        >
          <span class="p-menuitem-text" data-pc-section="label">
            {{ menuItem.label }}
          </span>
        </a>
        <a
          [ngClass]="{
            'menu-item-with-children': menuItem.items && menuItem.items.length > 0,
            'hidden': menuItem.class === 'hidden'
          }"
          class="p-ripple p-element p-menuitem-link"
          [routerLink]="menuItem.routerLink"
          routerLinkActive="p-menuitem-link-active"
          tabindex="-1"
          data-pc-section="action"
          *ngIf="menuItem.routerLink"
        >
          <span class="p-menuitem-text" data-pc-section="label">
            {{ menuItem.label }}
          </span>
        </a>
      </ng-template>
    </p-menubar>
    <!--<div class="menu-{{menuName}} bg-primary p-4 lg:bg-transparent lg:p-0 w-full text-lg ">
      <ul class="flex flex-col lg:flex-row">
        <li *ngFor="let menuItem of menu.items" class="group relative">
          <span class="flex justify-between">
            <a [routerLink]="menuItem.url" [routerLinkActive]="['is-active']" [routerLinkActiveOptions]="{exact: true}" class="text-white font-bold lg:mx-2 lg:px-3 lg:group-hover:bg-white lg:group-hover:text-primary py-3">
              <span class="inline-flex">
                {{menuItem.title}}
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="menuItem.children && menuItem.children.length > 0" class="lg:block hidden">
                  <path d="M12 15L7 10H17L12 15Z" fill="#F8D100" class="group-hover:!fill-primary"/>
                </svg>
              </span>
            </a>
            <button id="menuItemToggle" [attr.data-dropdown-toggle]="'dropdownNavbar'+menuItem.id" [attr.aria-controls]="'dropdownNavbar'+menuItem.id" class="block lg:hidden text-3xl text-secondary" [attr.aria-expanded]="false" *ngIf="menuItem.children && menuItem.children.length > 0">+</button>
          </span>
          <ul [id]="'dropdownNavbar'+menuItem.id" *ngIf="menuItem.children && menuItem.children.length > 0" class="relative lg:!absolute  w-full !transform-none hidden lg:!mt-[42px] h-full lg:!inset-0 lg:!absolute lg:!offset-none lg:group-hover:!block bg-white lg:rounded-b-md lg:shadow-lg z-10 lg:!mx-2 text-primary">
            <li *ngFor="let menuItem of menuItem.children" class="border-t border-gray-100 px-4 py-2">
              <a [routerLink]="menuItem.url">{{menuItem.title}}</a>
            </li>
          </ul>
        </li>
      </ul>
      <div class="relative text-center block lg:hidden">
        <a href="https://mijngezondeschool.nl/" target="_blank" class="border-secondary border py-4 px-6 inline-flex items-center rounded-lg text-white font-bold">
          Login
          <svg class="ml-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 21V19H19V5H12V3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H12ZM10 17L8.625 15.55L11.175 13H3V11H11.175L8.625 8.45L10 7L15 12L10 17Z" fill="#F8D100"/>
          </svg></a>
      </div>
    </div>-->
  </ng-container>
  <ng-template #footerMenu>
    <div class="menu menu-{{menuName}}">
      <div class="grid grid-cols-4 pb-12 gap-5">
        <div class="lg:col-span-1 col-span-4" *ngFor="let menuItem of menu.items">
          <h3 class="text-white text-2xl hover:underline mb-2"><a [routerLink]="menuItem.url" *ngIf="menuItem.internal">{{menuItem.title}}</a><a [href]="menuItem.url" *ngIf="!menuItem.internal">{{menuItem.title}}</a></h3>
          <ul *ngIf="menuItem.children">
            <li *ngFor="let menuItem of menuItem.children" class="mb-1">
              <a [routerLink]="menuItem.url" class="inline-flex items-center text-[#FEDCFF] group focus:outline" *ngIf="menuItem.internal"><svg class="mr-4" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 18.0005L12.6 16.5505L16.15 13.0005H4V11.0005H16.15L12.6 7.45049L14 6.00049L20 12.0005L14 18.0005Z" fill="#F8D100"/>
              </svg> <span class="group-hover:underline">{{menuItem.title}}</span></a>
              <a [href]="menuItem.url" class="inline-flex items-center text-[#FEDCFF] group focus:outline" *ngIf="!menuItem.internal"><svg class="mr-4" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 18.0005L12.6 16.5505L16.15 13.0005H4V11.0005H16.15L12.6 7.45049L14 6.00049L20 12.0005L14 18.0005Z" fill="#F8D100"/>
              </svg> <span class="group-hover:underline">{{menuItem.title}}</span></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </ng-template>
</div>
