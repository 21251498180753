import {Component, Input, OnInit} from '@angular/core';
import {TextAndImageParagraph} from "../../../models/node.model";
import {NgClass, NgIf, CommonModule} from "@angular/common";
import {TextContentComponent} from "../../text-content/text-content.component";

@Component({
  selector: 'app-text-and-image-paragraph',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    CommonModule,
    TextContentComponent
  ],
  templateUrl: './text-and-image-paragraph.component.html',
  styleUrl: './text-and-image-paragraph.component.scss'
})
export class TextAndImageParagraphComponent implements OnInit {
  @Input() withContainer = true;
  @Input() paragraph!: TextAndImageParagraph;

  bgClass: string = '';
  imageContainerClasses = '';
  textContainerClasses = '';

  imgUrl: undefined | string;
  isWhiteText: boolean = false;
  isPurpleList: boolean = false;

  image = '';

  ngOnInit() {
    this.imageContainerClasses = '';
    this.textContainerClasses = '';


    const variations = this.paragraph?.image?.mediaImage?.variations;
    // Reset variables to prevent old image from showing image from previous page on a page without header image
    this.imgUrl =  undefined;

    if(variations) {
      this.imgUrl = variations.find(v => v?.name === 'WIDE1090PX_SCALE_CROP')?.url;
    }

    if (this.paragraph.imageSize) {
      if (this.paragraph.imagePosition === 'left') {
        this.textContainerClasses += ' p-4 md:my-8 md:mx-8';
      } else if (this.paragraph.imagePosition === 'right') {
        this.textContainerClasses += ' p-4 md:mx-8 md:my-8';
      }
    }

    if (this.paragraph.imagePosition === 'left' || this.paragraph.imagePosition === 'right') {
      if(variations) {
        this.imgUrl = variations.find(v => v?.name === 'SQUARE_LARGE')?.url;
      }
      if (this.paragraph.imagePosition === 'left') {
        this.imageContainerClasses += ' order-1';
        this.textContainerClasses += ' order-2';
      } else if (this.paragraph.imagePosition === 'right') {
        this.imageContainerClasses += ' order-2';
        this.textContainerClasses += ' order-1';
      }

      if (this.paragraph.imageSize === '60') {
        this.imageContainerClasses += ' md:w-3/5';  // 60%
        this.textContainerClasses += ' md:w-2/5';   // 40%
      } else if (this.paragraph.imageSize === '50') {
        this.imageContainerClasses += ' md:w-1/2';  // 50%
        this.textContainerClasses += ' md:w-1/2';   // 50%
      } else if (this.paragraph.imageSize === '30') {
        this.imageContainerClasses += ' md:w-2/5';  // 30%
        this.textContainerClasses += ' md:w-3/5';   // 70%
      }

    } else if (this.paragraph.imagePosition === 'top' || this.paragraph.imagePosition === 'bottom') {
      this.imageContainerClasses += ' w-full';  // 100%
      this.textContainerClasses += ' w-full';   // 100%
    }

    if (this.paragraph.color?.color === '#F8D100') {
      this.isPurpleList = true;
    }
    if (this.paragraph.color?.color === '#481249') {
      this.isWhiteText = true;
    }
  }


}
