import {Component, Input, OnInit} from '@angular/core';
import {BreadCrumb, DrupalNode} from "../../../models/node.model";
import {NewsOverviewComponent} from "./news-overview/news-overview.component";
import {NgIf} from "@angular/common";
import {HeaderEntityComponent} from "../../header-entity/header-entity.component";
import {ParagraphsComponent} from "../../paragraphs/paragraphs.component";
import {EventNodeComponent} from "../event-node/event-node.component";
import {EventOverviewComponent} from "./event-overview/event-overview.component";
import {SearchOverviewComponent} from "./search-overview/search-overview.component";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-overview-node',
  standalone: true,
  imports: [
    NewsOverviewComponent,
    NgIf,
    HeaderEntityComponent,
    ParagraphsComponent,
    EventNodeComponent,
    EventOverviewComponent,
    SearchOverviewComponent
  ],
  templateUrl: './overview-node.component.html',
  styleUrl: './overview-node.component.scss'
})
export class OverviewNodeComponent implements OnInit{
  @Input() node!: DrupalNode;
  @Input() breadcrumbs!: Array<BreadCrumb>;
  public isNotFound = false;

  constructor(private activatedRoute: ActivatedRoute) {

  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.isNotFound = params['notFound'];
    });
  }
}
